<template>
  <p></p>
  <hr>
  <div v-if="cntSelBill!=1" class="container-fluid">{{ $t('user.users.chooseOneAccount') }}</div>
  <p></p>
  <div v-if="cntSelBill==1" class="container-fluid">
    <div class="row">
      <div class="col-1">
        <b>{{ $t('public.bill.bill_id') }}:</b> {{ data_one.bill_id }}.
      </div>
      <div class="col-4">
        <b>{{ $t('public.bill.bill_name') }}:</b> {{ data_one.bill_name }}.
      </div>
      <div class="col-1">
        <b>{{ $t('public.bill.bets_koef') }}:</b>
      </div>
      <div class="col-2">
        <Controls type="el-input"
                  v-model="data_one.bets_koef"
                  code="bets_koef"
                  :adddata="{
                valid: validerr.get('bets_koef')
              }"
        ></Controls>
      </div>
      <div class="col-1">
        <el-button class="btn-success" @click="saveNew">{{ $t('bill.bill.ok') }}</el-button>
      </div>
      <div class="col-1">
        <el-button class="btn btn-danger" @click="delCur"><font-awesome-icon icon="trash" /></el-button>
      </div>
      <div class="col-2">
      </div>
    </div>
  </div>
  <hr>
  <p></p>
  <el-table
      v-if="data.length>0 && !is_sel"
      :data="data"
      style="width: 100%"
      border
      :scrollbar-always-on="true"
  >
    <el-table-column prop="bill_id" :label="$t('public.bill.bill_id')" />
    <el-table-column prop="bill_name" :label="$t('public.bill.bill_name')" />
    <el-table-column prop="bets_koef" :label="$t('public.bill.bets_koef')" >
      <template #default="props">
        <span v-if="props.row.is_edit!=true">{{props.row.bets_koef}}</span>
        <Controls v-if="props.row.is_edit==true" type="el-input"
                  v-model="props.row.bets_koef"
                  code="bets_koef"
                  :adddata="{
                    valid: validerrrow.get('bets_koef')
                  }"
        ></Controls>
      </template>
    </el-table-column>
    <el-table-column :label="$t('public.action')">
      <template #default="scope">
        <button v-if="scope.row.is_edit!=true" @click="editRow(scope.$index)" type="button" class="btn btn-primary p-1" :title="$t('public.actions.edit')">
          <div class="icon-size"><font-awesome-icon icon="pencil-alt" /></div>
        </button>
        <button v-if="scope.row.is_edit==true" @click="saveRow(scope.$index, scope.row)" type="button" class="btn btn-success p-1" :title="$t('public.actions.save')">
          <div class="icon-size"><font-awesome-icon icon="save" /></div>
        </button>
        <button @click="delRow(scope.$index, scope.row)" type="button" class="btn btn-danger m-2 p-1" :title="$t('public.actions.del')">
          <div class="icon-size"><font-awesome-icon icon="trash" /></div>
        </button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
import BillService from "@/services/bill.service";
import {watch, inject, computed, onMounted, ref, watchEffect} from "vue";
import {useStore} from "vuex";
import Controls from "@/components/Controls/Main.vue"

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const set_result = inject('set_result')

const store = useStore()

const is_super = inject('is_super')

const currentUser = inject('currentUser')

const cntSelBill = inject('cntSelBill')
const SelOneBill = inject('SelOneBill')

const sel_row = ref({})
const is_sel = ref(false)
const type = ref('')

function onSelRow(params) {
  sel_row.value=params.sel_row
  is_sel.value=params.is_sel
  type.value=params.type
}

const data_one = ref({})
const data = ref([])

const validatas = inject('validatas')
const validerr = ref(new Map())
const validerrrow = ref(new Map())
const verdata = ref(new Map([
  ['bets_koef', {min: 0.01, max: 100, reg: '^[0-9,.]*$'}]
]));

function getList() {
  BillService.getBillsBetsKoef().then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        data.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      })
}

function getBill(bill_id)
{
  BillService.getBill(bill_id).then(
      (response) => {
        data_one.value.bill_id=response.data.id
        data_one.value.bill_name=response.data.name
        data_one.value.bets_koef=response.data.bets_koef
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function saveBillBetsKoef(dts, idx)
{
  BillService.saveBillBetsKoef(dts).then(
      () => {
        if(idx!==undefined) {
          data.value[idx].is_edit=false
        } else {
          set_result()
        }

        getList()
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function saveNew()
{
  data_one.value.bets_coef_manual = 'true'
  data_one.value.bets_koef=data_one.value.bets_koef.replace(',', '.')

  validerr.value = validatas(data_one.value, verdata.value)

  if(validerr.value.size>0) {
    return false
  }

  saveBillBetsKoef(data_one.value)
}

function delCur()
{
  data_one.value.bets_coef_manual = 'false'
  data_one.value.bets_koef='1'
  data_one.value.ip_bill_id=SelOneBill.value

  saveBillBetsKoef(data_one.value)
}

function saveRow(idx, dt)
{
  dt.bets_coef_manual = 'true'
  dt.bets_koef=dt.bets_koef.replace(',', '.')
  validerrrow.value = validatas(dt, verdata.value)

  if(validerrrow.value.size>0) {
    return false
  }

  saveBillBetsKoef(dt, idx)
}

function delRow(idx, dt)
{
  let result = confirm($t('app.are_you_sure'))

  if(result==true) {
    dt.bets_koef=1
    dt.bets_coef_manual = 'false'

    saveBillBetsKoef(dt, idx)
  }
}

function editRow(idx)
{
  for(let dv in data.value) {
    data.value[dv].is_edit=false
  }

  data.value[idx].is_edit=true
}

watchEffect(() => {
  if(cntSelBill.value==1) {
    getBill(SelOneBill.value)
  }
})

onMounted(() => {
  getList()
})
</script>

<style>
.blocked_user {
  background-color: #c7ced8;
  font-style: italic;
}

.user_active {
  color: green
}

.user_not_active {
  color: #999999;
}
</style>
